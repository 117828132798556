<section class="is-info is-fullheight is-bold">
    <div class="hero-body">
        <div class="container">

            <h1 class="title">Generate Signature Form</h1>

            <!-- form goes here -->
            <form (ngSubmit)="processForm()" #signatureForm="ngForm" autocomplete="off">
                <!-- name -->
                <div class="field">
                    <input type="text" autocomplete="off" name="domain" id="domain" class="input" required=""
                        placeholder="Domain" [(ngModel)]="domain">
                </div>

                <!-- email -->
                <div class="field">
                    <input type="text" name="path" autocomplete="off" class="input" required required="true"
                        placeholder="Enter the URL path" [(ngModel)]="path">
                </div>

                <!-- email -->
                <div class="field">
                    <input type="password" name="secret-key" autocomplete="off" class="input" required="true" required
                        placeholder="Enter the Secret Key" [(ngModel)]="secretKey">
                </div>

                <!-- message -->
                <div class="field">
                    <textarea class="textarea" name="body" autocomplete="off" placeholder="Payload Body" rows="10"
                        [(ngModel)]="body"></textarea>
                </div>

                <button type="submit" [disabled]="!signatureForm.form.valid" class="button is-danger is-large">Generate
                    Signature</button>

            </form>

            <div id="output" *ngIf="signature">
                <h2>{{signature}}</h2> <button type="button" class="button is-danger is-small"
                    (click)="copyToClipboard()">Copy Signature</button>
            </div>
        </div>
    </div>
</section>