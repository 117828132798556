<!-- header -->
<nav class="navbar header">

    <!-- logo -->
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img src="../../../assets/images/bantupay_logo.png" height="200" alt="BantuPay Logo">
      </a>
    </div>
  </nav>
  