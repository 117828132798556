import { Component, OnInit } from '@angular/core';
import * as StellarSdk from 'stellar-sdk';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  domain: string;
  path: string;
  secretKey: string;
  signature: string;
  body: string;

  constructor() { }

  ngOnInit(): void {
  }

  sign(uri: string, body: string, secretKey: string): string {
    //handle undefined cases
    uri = uri?uri:""
    body = body?body:""

    const keyPair = StellarSdk.Keypair.fromSecret(secretKey);
    const data = `${uri}${body}`;
    const signedData = keyPair.sign(Buffer.from(data)).toString('base64');
    return signedData;
  }

  processForm(): void {
    try {
      this.signature = this.sign(this.path, this.body, this.secretKey);
    } catch (Error) {
      this.signature = null;
      alert(Error.message);
    }
  }

  copyToClipboard(): void {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.signature));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
